.downloadButton {
  a {
    svg {
      height: 3rem;
      width: 3rem;
      color: #e27900;
    }
    svg:hover {
      color: #e98718;
    }
  }
}
