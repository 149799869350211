/*=============================================
=            breadcrumb            =
=============================================*/

.breadcrumb-area {
  padding: 80px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 1;
  background-image: url(../../../assets/img/slider/slider1.jpg);

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: $black;
    opacity: 0.75;
    z-index: -1;
  }
  // Responsive
  @media #{$tablet-device} {
    padding: 60px 0;
  }
  @media #{$large-mobile} {
    padding: 40px 0;
  }
  @media #{$small-mobile} {
    padding: 25px 0;
  }
  @media #{$extra-small-mobile} {
  }
}
/*-- Page Banner --*/
.page-banner {
  & h1 {
    font-size: 48px;
    color: $white;
    font-weight: 500;
    letter-spacing: 0.1px;
    margin-top: -10px;
    // Responsive
    @media #{$tablet-device} {
      font-size: 36px;
      margin-top: -5px;
    }
    @media #{$large-mobile} {
      font-size: 30px;
      margin-top: -5px;
    }
    @media #{$small-mobile} {
      font-size: 24px;
    }
  }
}
/*-- Page Breadcrumb --*/
.page-breadcrumb {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  & li {
    color: $white;
    font-size: 18px;
    font-family: $poppins;
    font-weight: 300;
    letter-spacing: 0.5px;
    line-height: 1;
    margin-top: 10px;
    // Responsive
    @media #{$small-mobile} {
      font-size: 16px;
    }
    &::after {
      content: "-";
      margin: 0 6px;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
    & a {
      &:hover {
        color: $theme-color--default;
      }
    }
  }
}

/*=====  End of breadcrumb  ======*/
