.body {
  width: 90%;
  height: -moz-fit-content;
  margin: auto;
  height: 100vh;

  ul {
    margin: auto;
    width: 100%;
  }
}
